import.meta.glob([
    '../images/**',
    '../fonts/**.{woff,woff2}',
]);

window.facebookFbq = (...args) => {
    if (typeof fbq === 'undefined') {
        return
    }
    
    fbq(...args);
}

function replaceText(searchText = '', replacementHTML = '') {
    // Alle Elemente auf der Seite durchlaufen
    document.querySelectorAll('*').forEach(function (node) {
        // Überprüfen, ob das aktuelle Element Textknoten enthält
        node.childNodes.forEach(function (child) {
            if (child.nodeType === 3) { // 3 bedeutet, es ist ein Textknoten
                // Überprüfen, ob der Textknoten den Suchtext enthält
                if (child.nodeValue.includes(searchText)) {
                    // Erstellen eines neuen HTML-Knotens mit dem Ersetzungstext
                    const newNode = document.createElement('span');
                    newNode.innerHTML = child.nodeValue.replace(searchText, replacementHTML);
                    // Ersetzen des alten Textknotens durch den neuen HTML-Knoten
                    node.replaceChild(newNode, child);
                }
            }
        });
    });
}

replaceText('No More! White Money', '<s>No</s> More! White Money');